import React, {useEffect, useReducer, useState} from 'react'

export const LocaleContext = React.createContext({ locale: 'en-US', multiLang: [] })

export const FilterContext = React.createContext(false)


export function FiltersProvider(props) {
    const [segmentFilter, setSegmentFilter] = useState([])
    const [benefitsFilter, setBenefitsFilter] = useState([])
    const [certificationsFilter, setCertificationsFilter] = useState([])
    const [flavorFilter, setFlavorFilter] = useState([])
    const [collectionsFilter, setCollectionsFilter] = useState([])
    const [recipeFilter, setRecipeFilter] = useState([])
    const values = {
        segmentFilter,
        setSegmentFilter,
        benefitsFilter,
        setBenefitsFilter,
        certificationsFilter,
        setCertificationsFilter,
        flavorFilter,
        setFlavorFilter,
        collectionsFilter,
        setCollectionsFilter,
        recipeFilter,
        setRecipeFilter
    }

    return (
        <FilterContext.Provider
            value={{ ...values }}>
            {props.children}
        </FilterContext.Provider>
    )
}
