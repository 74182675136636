import React, { useState, useEffect } from 'react'
import * as styles from './mobileHeader.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link, navigate } from 'gatsby';
import { BiSearch, BiMap, BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { useLocalPath } from '../../hooks/useLocalPath';
import { useLocale } from "../../hooks/useLocale"
import { useLocation } from "@reach/router"
import { useDictionaryQuery } from '../../../fragments/hooks/useDictionaryQuery';
const MobileHeader = ({ logo, headerMenuMobile, navBarColor }) => {

    let [show, setShow] = useState(true)
    let [subLink, setSubLink] = useState(false)
    const [activeLang, setActiveLang] = useState("")
    const location = useLocation()
    const locale = useLocale()
    const path = useLocalPath("search")
    const {googlePlay, appleStore} = useDictionaryQuery()
    const subLinkHide = () => {
        return (
            <>
                {setSubLink(false)}
                {setShow(false)}
            </>
        )
    }
    const closeNav = (e) => {
        document.getElementById('naviToggle').checked = false
        setShow(true)
        document.querySelectorAll('[data-menu="checkbox"]').forEach(element => {
            element.checked = false
        })
    }

    useEffect(() => {
        document.querySelectorAll('.navigation-link').forEach(element => {
            element.addEventListener('click', closeNav)
        })
        return () => {
            document.querySelectorAll('.navigation-link').forEach(element => {
                element.removeEventListener('click', closeNav)
            })
        }
    })
    useEffect(() => {
        if (locale === "en-US") {
            setActiveLang("en")
        } else if (locale === "es-US") {
            setActiveLang("es")
        }
        else if (locale === "en-CA") {
            setActiveLang("en")
        } else if (locale === "fr-CA") {
            setActiveLang("fr")
        }
    }, [location])

    return (
        <div className={styles.mobileContainer} style={{ background: navBarColor }}>
            <div className={styles.wrapper}>
                <div className={styles.mobileLogo}>
                    <Link to = {useLocalPath('')}>
                        <GatsbyImage
                            image={logo.gatsbyImageData}
                            alt={`${logo.title} image`}
                            loading='eager'
                            objectFit='contain'
                        />
                    </Link>
                </div>
                <div className={styles.rightCorner}>
                    <div className={styles.icons}>
                        <div onClick={() => navigate(path)}>
                            <label htmlFor='search'> 
                                <BiSearch fontSize='1.5rem' color='white' /> 
                                <input  id='searchOnMobile' type="hidden" /> 
                            </label>
                        </div>
                        {/* <Link to={useLocalPath('search')} alt = 'search'><BiSearch fontSize='1.5rem' color='white' /></Link> */}
                        { locale === 'en-US' && <Link to={useLocalPath('where-to-buy')} alt = 'Where to buy'><BiMap fontSize='1.5rem' color='white' /> </Link>}
                        { locale === 'es-US' && <Link to={useLocalPath('where-to-buy')} alt = 'Where to buy'><BiMap fontSize='1.5rem' color='white' /> </Link>}
                    </div>
                    <input
                        type="checkbox"
                        id="naviToggle"
                        className={styles.navigation__checkbox}
                        hidden
                    />
                    <label
                        htmlFor="naviToggle"
                        className={styles.navigation__button}>
                        <span className={styles.navigation__icon}>&nbsp;</span>
                    </label>
                    {/* {<label htmlFor="naviToggle" onClick={() => setShow(false)}>
                            <AiOutlineMenu />
                        </label>}
                        {!show && <label onClick={closeNav} > 
                            <AiOutlineClose />
                        </label>} */}

                    <div className={`${styles.mobNavShow}`}>
                        <div className={styles.linkActive}>
                            <ul>
                                {headerMenuMobile.map((item: any, id: number) => {
                                    const { title, slug, menuItems, linkType, externalLink } = item

                                    if (menuItems) {
                                        return (
                                            <li className={styles.menuLinks} key={id}>
                                                <input type="checkbox" name="" id={`sub-link-${id}`} hidden data-menu="checkbox" className={styles.linkCheckBox} />
                                                <div className={styles.titleBox}>
                                                    <label htmlFor={`sub-link-${id}`} >{title}</label>
                                                    <BiChevronRight />
                                                </div>
                                                {
                                                    <div className={styles.menuLinkWrapper}>
                                                        <div className={styles.titleBoxInner}>
                                                            <BiChevronLeft />
                                                            <label htmlFor={`sub-link-${id}`} >{title}</label>

                                                        </div>
                                                        <div className={styles.innerLink}>
                                                            {menuItems.map((item: any, index: number) => {
                                                                const { title, thumbnailImage, slug } = item
                                                                switch (item.__typename) {
                                                                    case "ContentfulComponentLink":
                                                                        return (
                                                                            <Link to={useLocalPath(slug)} key={index} className={`navigation-link ${styles.titleInner}`}>
                                                                                <div>{title}</div>
                                                                            </Link>
                                                                        )
                                                                    default:
                                                                        return (
                                                                            <Link to={useLocalPath(slug)} key={index} className={`navigation-link ${styles.titleInner}`}>
                                                                                <p>{title}</p>
                                                                            </Link>
                                                                        )
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </li>
                                        )
                                    }
                                    if(externalLink){
                                        return (
                                            <li key={id} className={`navigation-link ${styles.menuLinks} ${styles.titleBox}`}>
                                                <a href={externalLink}>
                                                    {title}
                                                </a>
                                            </li>
                                        )
                                    } else return (
                                        <li key={id}>
                                            <div className={`navigation-link ${styles.menuLinks} ${styles.titleBox}`}>
                                                <Link to={useLocalPath(slug)}>{title}</Link>
                                            </div>
                                        </li>
                                    )
                                })}
                                <li>
                                    {(locale === "en-US" || locale === "es-US") && (
                                        <div className={styles.selectLang}>
                                            <div className={`${activeLang === "en" && styles.active}`}>
                                                {activeLang === "en" ? (
                                                    <span >EN</span>
                                                ) : (
                                                    <a href="/us/en" className={styles.inActive}>EN</a>
                                                )}
                                            </div>
                                            
                                            <div className={`${activeLang === "es" && styles.active}`}>
                                                {activeLang === "es" ? (
                                                    <span >ES</span>
                                                ) : (
                                                    <a href="/us/es" className={styles.inActive}>ES</a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {(locale === "en-CA" || locale === "fr-CA") && (
                                        <div className={styles.selectLang}>
                                            <div className={`${activeLang === "en" && styles.active}`}>
                                                {activeLang === "en" ? (
                                                    <span >EN</span>
                                                ) : (
                                                    <a href="/ca/en" className={styles.inActive}>EN</a>
                                                )}
                                            </div>
                                            
                                            <div className={`${activeLang === "fr" && styles.active}`}>
                                                {activeLang === "fr" ? (
                                                    <span >FR</span>
                                                ) : (
                                                    <a href="/ca/fr" className={styles.inActive}>FR</a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileHeader