import { useStaticQuery, graphql } from 'gatsby'
import {useLocale} from './useLocale'
export const useSiteQuery = () => {
    const locale = useLocale();
    const {
        allContentfulGlobalSite :{edges}
    } = useStaticQuery(
        graphql`
        query globalSite{
            allContentfulGlobalSite {
                edges {
                    node {
                        node_locale
                      logo {
                        gatsbyImageData
                        title
                        file{
                          url
                        }
                      }
                      topStrip {
                        title
                        slug
                      }
                      smartlabel {
                        gatsbyImageData
                        title
                      }
                      foodService {
                        title
                        externalLink
                      }
                      socialMediaIcons {
                        image {
                          gatsbyImageData
                          title
                        }
                        externalLink
                      }
                      emailAddress
                      firstName
                      lastName
                      zipCode
                      dateOfBirth
                      headerMenuMobile {
                        ... on ContentfulComponentMenuGroup {
                          title
                          menuItems {
                            #... on ContentfulPageRegenerative {
                            #  title : thumbnailTitle
                            #  slug
                            #}
                            ... on ContentfulPageProductsCategory {
                              title: thumbnailText
                              slug
                            }
                            ... on ContentfulComponentLink {
                              title
                              slug
                              externalLink
                            }
                          }
                        }
                        ... on ContentfulComponentLink {
                          title
                          slug
                          externalLink
                        }
                      }
                      headerMenuItems {
                        ... on ContentfulComponentMenuGroup {
                          title
                          linkType
                          menuItems {
                            __typename
                            #... on ContentfulPageRegenerative {
                            #  title: thumbnailTitle
                            #  thumbnailImage {
                            #    gatsbyImageData(quality:100)
                            #    title
                            #  }
                            #  slug
                            #}
                            ... on ContentfulPageProductsCategory {
                              title: thumbnailText
                              thumbnailImage {
                                gatsbyImageData(quality:100)
                                title
                              }
                              slug
                            }
                            ... on ContentfulComponentLink {
                              title
                              slug
                              externalLink
                            }
                          }
                        }
                        ... on ContentfulComponentLink {
                          title
                          slug
                          externalLink
                        }
                      }
                      footerItems {
                        slug
                        title
                        externalLink
                      }
                      unileverCopyright
                      footerText {
                        raw
                      }
                      footerUnileverLogo {
                        gatsbyImageData
                        title
                      }
                      topStripBackgroundColor
                      navBarColor
                      countriesAtFooter {
                        title
                        externalLink
                      }
                    }
                  }
              }
        }`
    )

    const localeData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-US')
    const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
   ;
    
    return site

}