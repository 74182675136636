module.exports = {
    mapLocale: {
        'en-US': 'us/en',
        'es-US': 'us/es',
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr'
    },
    cookieLang: {
        'en-US': 'en',
        'es-US': 'es',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'cafe-collective': 'en'
    },
    siteUrl:{
        'en-US': 'us/en',
        'es-US': 'us/es',
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr'
    },
    mapLangSelector: {
        'en-US': 'United States (English)',
        'es-US': 'United States (Spanish)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)'
    },
    mapAddressCountry: {
        'en-US': 'United States of America',
        'es-US': 'United States of America (Spanish)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)'
    },
    mapFetchifyCode: {
        'en-GB': 'GBR',
        'en-US': 'USD',
        'es-US': 'USD'
    },
    userCentric:{
        'en-US': 'eWUzxTQFu',
        'es-US': 'eWUzxTQFu',
        'en-CA': 'IN8HUexpIRsVmq',
        'fr-CA': 'Jk3n4iFtQztEqy',
        'cafe-collective': 'eWUzxTQFu'
    },
    zendeskCookieLang: {
        'en-GB': 'en',
        'de': 'de',
        'en-EG': 'en',
        'ar-EG': 'ar',
        'en-US': 'en',
        'fr-FR': 'fr',
        'pl': 'pl',
        'es-US': 'es',
        'en-AU': 'en',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'tr': 'tr',
        'ja-JP': 'ja',
        'ur-PK': 'ur',
        'en-PK': 'en',
        'en-AE': 'en',
        'ar-AE': 'ar'
    },
    zendeskChatDept: {
        'en-US': 'united states of america',
        'en-AU': 'australia',
        'en-GB': 'united kingdom',
    },
    bazarvoice:{
        'en-US': 'en_US',
        'es-US': 'es_US',
        'en-CA': 'en_CA',
        'fr-CA': 'fr_CA'
    } 
}