import React, { useEffect, useState } from "react"
import * as styles from "./topStrip.module.scss"
import { Link } from "gatsby"
import { useSiteQuery } from "../../../fragments/hooks/useSiteQuery"
import { useLocalPath } from "../../hooks/useLocalPath"
import { GatsbyImage } from "gatsby-plugin-image"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { useLocale } from "../../hooks/useLocale"
import { useLocation } from "@reach/router"

const TopStrip = ({ topStripBackgroundColor }) => {
  const { topStrip, foodService, smartlabel } = useSiteQuery()
  const [activeLang, setActiveLang] = useState("")
  const location = useLocation()
  const locale = useLocale()
  useEffect(() => {
    if (locale === "en-US") {
      setActiveLang("en")
    } else if (locale === "es-US") {
      setActiveLang("es")
    } else if (locale === "fr-CA") {
      setActiveLang("fr")
    } else if (locale === "en-CA") {
      setActiveLang("en")
    } 
  }, [location])

  return (
    <div style={{ background: topStripBackgroundColor }}>
      <div className={`container-lg ${styles.wrapper}`}>
        {(locale === "en-US" || locale === "es-US") && (
          <div className={styles.selectLang}>
            <div className={`${activeLang === "en" && styles.active}`}>
              {activeLang === "en" ? (
                <span>EN</span>
              ) : (
                <a href="/us/en">EN</a>
              )}
            </div>
            <span className={styles.changeLang}>|</span>
            <div className={`${activeLang === "es" && styles.active}`}>
              {activeLang === "es" ? (
                <span>ES</span>
              ) : (
                <a href="/us/es">ES</a>
              )}
            </div>
          </div>
        )}
        {(locale === "en-CA" || locale === "fr-CA") && (
          <div className={styles.selectLang}>
            <div className={`${activeLang === "en" && styles.active}`}>
              {activeLang === "en" ? (
                <span>EN</span>
              ) : (
                <a href="/ca/en">EN</a>
              )}
            </div>
            <span className={styles.changeLang}>|</span>
            <div className={`${activeLang === "fr" && styles.active}`}>
              {activeLang === "fr" ? (
                <span>FR</span>
              ) : (
                <a href="/ca/fr">FR</a>
              )}
            </div>
          </div>
        )}
        <ul className={styles.topLabel}>
          {/* <li className={styles.topLabelRight}>
            <div><Link to = {foodService.externalLink} target = '_blank'>{foodService.title}</Link> <BsBoxArrowUpRight fontSize='0.7rem'/></div>
            <div className={styles.smartlabel} >
              <Link  to = 'https://www.unileverusa.com/brands/smartlabel/'  target = '_blank'>
                <GatsbyImage
                  image = {smartlabel.gatsbyImageData}
                  alt={smartlabel.title} 
                  className={styles.smartlabel}
                  loading= 'eager' />
              </Link> 
              
            </div>
          </li> */}
          {topStrip.map((item: any, id: number) => {
            return (
              <li className={styles.topLabelLeft} key={id}>
                <Link to={useLocalPath(item.slug)}>{item.title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default TopStrip
