// extracted by mini-css-extract-plugin
export var active = "topStrip-module--active--9a72b";
export var button = "topStrip-module--button--451b7";
export var cafeFormIntro = "topStrip-module--cafe-form-intro--83749";
export var changeLang = "topStrip-module--change-lang--6d8c6";
export var highlight = "topStrip-module--highlight--fbe0c";
export var redButton = "topStrip-module--red-button--372b1";
export var redTransparentButton = "topStrip-module--red-transparent-button--13307";
export var selectLang = "topStrip-module--select-lang--05765";
export var smartlabel = "topStrip-module--smartlabel--72af4";
export var topLabel = "topStrip-module--top-label--d3681";
export var topLabelLeft = "topStrip-module--top-label-left--198a0";
export var topLabelRight = "topStrip-module--top-label-right--f8e24";
export var transparentButton = "topStrip-module--transparent-button--7a65d";
export var wrapper = "topStrip-module--wrapper--beac9";