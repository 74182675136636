import React from 'react'
import * as styles from './socialMedia.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';
import { useSiteQuery } from '../../fragments/hooks/useSiteQuery';
import { useDictionaryQuery } from '../../fragments/hooks/useDictionaryQuery';
import {Link} from 'gatsby'
const SocialMedia = ({marginSpace}) => {
  const {socialMediaIcons} = useSiteQuery()
  const {externalLinkLabel} = useDictionaryQuery()
  return (
    <ul className={styles.wrapper}>
        {socialMediaIcons.map((item:any, id:number) =>{
            const{image, externalLink} = item
            return(
                <li key = {id} >
                    <a href = {externalLink} target = '_blank' aria-label = {`${externalLinkLabel} ${image.title}`} title={externalLinkLabel} rel="noopener noreferrer" >
                      <GatsbyImage
                        image = {image.gatsbyImageData}
                        className = {marginSpace ? styles.spacedSocialIcon : styles.socialIcon}
                        alt = {`${image.title}`}/>
                    </a>
                </li>
            )
        })}
    </ul>
  )
}

export default SocialMedia