import React, {useState} from 'react'
import * as styles from './footer.module.scss'
import { useSiteQuery } from '../../../fragments/hooks/useSiteQuery'
import {Link} from 'gatsby'
import SocialMedia from '../../SocialMediaIcons/SocialMedia'
import {useLocalPath} from '../../hooks/useLocalPath'
import { GatsbyImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import { RichText} from '../../Shared/RichText/RichText'
import { useLocale } from '../../hooks/useLocale'
import { useDictionaryQuery } from '../../../fragments/hooks/useDictionaryQuery'
import Popup from '../../Cookie/Popup'

const Footer = () => {
  const{footerItems, unileverCopyright, footerText, footerUnileverLogo, countriesAtFooter} = useSiteQuery()
  const locale = useLocale()
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const [active, setActive] = useState('');
  const year = new Date().getFullYear();
  const {externalLinkLabel, cookieDetail} = useDictionaryQuery()
  const updateCountry = (e:any) =>{
    const activeCountry = (e.currentTarget.value)
    navigate(activeCountry)
  }
  const market = () => {
    switch(locale){
    case 'en-US':
      return 'https://ekaterratea.com/us/en'
    case 'es-US':
      return 'https://ekaterratea.com/us/es'
    case 'en-CA':
      return 'https://ekaterratea.com/ca/en'
    case 'fr-CA':
      return 'https://ekaterratea.com/ca/fr'
  }
}
  return (
    <footer className={`container ${styles.footerWrapper}`}>
      <div className= {`container-lg `}>
        <div className={styles.footer}>
          <div>
            <ul className={styles.footerNav}>
              {footerItems.map((item:any, id:number) =>{
                const{title, slug, externalLink} = item
                return(
                  <li className={styles.footerItem} key={id}>
                    <div className={styles.footerTitle}>
                      <a href={externalLink ? externalLink : useLocalPath(slug)} target = {externalLink ? '_blank' : '_self'}  aria-label = {externalLink ? `${externalLinkLabel} ${title}` : ''} title={externalLink?  externalLinkLabel: ''} rel="noopener noreferrer">{title}</a>
                    </div>
                  </li>
                )
              })}
              <li className={styles.privacyPopupLink} onClick={e => { setPrivacyOpen(true) }}> {cookieDetail}</li>
            </ul>
          </div>
          <div className={`${styles.wrapper} ${privacyOpen ? styles.privacyOpen : ''}`} >
              {
                  privacyOpen
                      ? <Popup setOpen={setPrivacyOpen} />
                      : null}
              {/* <CookiePopup setOpen={setPrivacyOpen} /> */}
          </div>
          <div>
            <div className={styles.share}>Share:</div>
            <div><SocialMedia/></div>
          </div>
        </div>
        <div className={styles.countrySection}>
          <div className={styles.ekaterra}>
            <div>
              <a href={market()} target='_blank'  rel="noopener noreferrer"  aria-label = {`${externalLinkLabel} ${footerUnileverLogo.title}`} title={externalLinkLabel}>
                <GatsbyImage
                  image = {footerUnileverLogo.gatsbyImageData}
                  alt= {`${footerUnileverLogo.title}`}
                  className = {styles.logo}
                  />
              </a>
            </div>
            <div className={styles.logoText}>
              <p className={styles.link_footer}>{unileverCopyright}{year}</p>
              <RichText content = {footerText} />
            </div>
          </div>
          <div>
            <select style = {{width : '200px', height: '30px'}}  onChange = {updateCountry}>
              {countriesAtFooter.map((item:any, id:number) =>{            
                return(
                  <option key = {id} value= {item.externalLink}>{item.title}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer