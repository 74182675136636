// extracted by mini-css-extract-plugin
export var button = "footer-module--button--ef52c";
export var cafeFormIntro = "footer-module--cafe-form-intro--a23b6";
export var countrySection = "footer-module--country-section--00b03";
export var ekaterra = "footer-module--ekaterra--b5637";
export var footer = "footer-module--footer--34771";
export var footerItem = "footer-module--footer-item--59b6c";
export var footerNav = "footer-module--footer-nav--06667";
export var footerTitle = "footer-module--footer-title--8ddc5";
export var footerWrapper = "footer-module--footer-wrapper--a8f38";
export var highlight = "footer-module--highlight--aa076";
export var link_footer = "footer-module--link_footer--a05c1";
export var logo = "footer-module--logo--1dbe3";
export var logoText = "footer-module--logo-text--45b54";
export var logoimg = "footer-module--logoimg--433c5";
export var privacyOpen = "footer-module--privacy-open--2f715";
export var privacyPopupLink = "footer-module--privacy-popup-link--7534b";
export var redButton = "footer-module--red-button--b8437";
export var redTransparentButton = "footer-module--red-transparent-button--efda5";
export var share = "footer-module--share--69c9a";
export var transparentButton = "footer-module--transparent-button--8847e";
export var wrapper = "footer-module--wrapper--b5217";