// extracted by mini-css-extract-plugin
export var button = "topNav-module--button--9d9d2";
export var cafeFormIntro = "topNav-module--cafe-form-intro--7b558";
export var highlight = "topNav-module--highlight--55f09";
export var links = "topNav-module--links--7d255";
export var logo = "topNav-module--logo--2a465";
export var menu = "topNav-module--menu--9338d";
export var menuImage = "topNav-module--menu-image--1a7f6";
export var menuItem = "topNav-module--menu-item--d13c9";
export var menuLinkWrapper = "topNav-module--menu-link-wrapper--000aa";
export var menuLinks = "topNav-module--menu-links--390f1";
export var navWrapper = "topNav-module--nav-wrapper--a81d7";
export var redButton = "topNav-module--red-button--7b9a4";
export var redTransparentButton = "topNav-module--red-transparent-button--18f75";
export var searchBox = "topNav-module--search-box--a3dfe";
export var searchBoxLabel = "topNav-module--search-box-label--31044";
export var text = "topNav-module--text--911e0";
export var textMenu = "topNav-module--text-menu--07fe5";
export var textTitle = "topNav-module--text-title--29c27";
export var titleBox = "topNav-module--title-box--0eff0";
export var transparentButton = "topNav-module--transparent-button--4bcf8";