import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./topNav.module.scss"
import { useLocalPath } from "../../hooks/useLocalPath"
import { FiChevronDown } from 'react-icons/fi'
import { BiSearch } from 'react-icons/bi'
import { navigate } from "gatsby"
import { useDictionaryQuery } from "../../../fragments/hooks/useDictionaryQuery"

import Logo from '../../../../static/tazo-assets/logo.jpg';

const TopNav = ({ logo, headerMenuItems, navBarColor }) => {
    const [showMenu, setShowMenu] = useState(false)
    const path = useLocalPath("search")

    const { searchForProducts } = useDictionaryQuery()
    return (
        <div style={{ background: navBarColor }}>
            <ul className={` container-lg ${styles.navWrapper}`}>
                <li className={styles.logo}>
                    <Link to={useLocalPath("")}>
                        {/* <GatsbyImage
                            image={logo.gatsbyImageData}
                            alt={`${logo.title}`}
                            loading="eager"
                            objectFit="contain"
                        /> */}
                        <img src={Logo} alt={`${logo.title}_img`} />
                    </Link>
                </li>
                {headerMenuItems.map((item: any, id: number) => {
                    const { title, slug, menuItems, linkType, externalLink } = item
                    if (menuItems) {
                        return (
                            <li className={styles.menuLinks} key={id}>
                                <div className={styles.titleBox}>{title}  <FiChevronDown /></div>
                                {
                                    <div className={styles.menuLinkWrapper}>
                                        <div className={linkType === 'Image Link' ? styles.menu : styles.textMenu}>
                                            {menuItems.map((item: any, index: number) => {                                     
                                                const { title, thumbnailImage, slug, externalLink} = item
                                                switch (item.__typename) {
                                                    case "ContentfulComponentLink":                           
                                                        return (
                                                            <Link to={useLocalPath(slug)} key={index}>
                                                                <p className={styles.textTitle}>{title}</p>
                                                            </Link>
                                                        )
                                                    
                                                    default:
                                                        return (
                                                            <Link to={useLocalPath(slug)} key={index}>
                                                                <GatsbyImage
                                                                    image={thumbnailImage.gatsbyImageData}
                                                                    alt={`${thumbnailImage.title} image`}
                                                                    className={styles.menuImage}
                                                                    objectFit="contain"
                                                                />
                                                                <p className={styles.text}>{title}</p>
                                                            </Link>
                                                        )
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                            </li>
                        )
                    }
                    if(externalLink){
                        return (
                            <li className={styles.links} key={id}>
                                <a href={externalLink}>
                                    {title}
                                </a>
                            </li>
                        )
                    } else return (
                        <li className={styles.links} key={id} >
                            <Link to={useLocalPath(slug)}>{title}</Link>
                        </li>
                    )
                })}
                <li>
                    {/* <Link to={useLocalPath('search')} alt={searchForProducts}> */}
                    <div onClick={()=>navigate(path)}>
                        <label className={styles.searchBoxLabel} htmlFor="search">
                            <BiSearch />
                            <input type="text" name="q" id="searchOnDesktop" max-length="2048" className={styles.searchBox} placeholder={searchForProducts} spellCheck="false" data-ms-editor="true" />
                        </label>
                    </div>
                    {/* </Link> */}
                </li>
            </ul>
        </div>
    )
}

export default TopNav
