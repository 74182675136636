import { useStaticQuery, graphql } from 'gatsby'
import {useLocale} from './useLocale'

export const useDictionaryQuery = () => {
    const locale = useLocale();
    const {
      allContentfulGlobalDictionary :{edges}
    } = useStaticQuery(
        graphql`
        query globalDictionarySite{
          allContentfulGlobalDictionary {
                edges {
                    node {
                        node_locale
                        clear
                        filterBy
                        apply
                        explore
                        externalLinkLabel 
                        searchForProducts
                        showMoreProducts
                        all
                        recipes
                        products
                        viewMoreProducts
                        viewMoreRecipes
                        noProductsFound
                        noRecipesFound
                        pleaseEnterHere
                        cookieDetail
                        tazo {
                          gatsbyImageData(quality: 100)
                          title
                        }
                        heroImage: cafeCollectiveSignUpPageBanner {
                          gatsbyImageData(quality: 100)
                          title
                        }
                        heroImageMobile: cafeCollectiveBannerMobile{
                          gatsbyImageData(quality: 100)
                          title
                        }
                        caffieneLevel
                        googlePlay {
                          gatsbyImageData
                          title
                        }
                        appleStore {
                          gatsbyImageData
                          title
                        }
                        cafeCollectiveIntroText
                        seoImage{
                          gatsbyImageData
                          title
                          file{
                            url
                          }
                        }
                    }
                  }
              }
        }`
    )

    const localeData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-US')
    const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
   ;
    
    return site

}