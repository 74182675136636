// extracted by mini-css-extract-plugin
export var PageContact = "header-module--PageContact--2a600";
export var active = "header-module--active--2176a";
export var blogBody = "header-module--blog-body--b4e90";
export var button = "header-module--button--d1ec5";
export var bv_averageRating_component_container = "header-module--bv_averageRating_component_container--5b8ae";
export var bv_main_container = "header-module--bv_main_container--99efb";
export var bv_numReviews_component_container = "header-module--bv_numReviews_component_container--30320";
export var cafeFormIntro = "header-module--cafe-form-intro--32f69";
export var contactUsAdditionalDesc = "header-module--contact-us-additional-desc--b1142";
export var contactUsDesc = "header-module--contact-us-desc--ed387";
export var container = "header-module--container--18126";
export var containerFs = "header-module--container-fs--0ebc1";
export var containerLg = "header-module--container-lg--0ea1a";
export var containerLgMd = "header-module--container-lg-md--59f27";
export var containerLgNp = "header-module--container-lg-np--92765";
export var containerMd = "header-module--container-md--d4870";
export var containerSm = "header-module--container-sm--c4026";
export var containerXl = "header-module--container-xl--60a97";
export var ee_container = "header-module--ee_container--b1fdd";
export var ee_list_item_label = "header-module--ee_list_item_label--56bf7";
export var emailUsWrapper = "header-module--email-us-wrapper--d4e19";
export var faq = "header-module--faq--05c2a";
export var header = "header-module--header--8066f";
export var highlight = "header-module--highlight--f4f4f";
export var messageBtn = "header-module--message-btn--23fe1";
export var page = "header-module--page--9c877";
export var pagingButtonSubmit = "header-module--paging-button-submit--be480";
export var product = "header-module--product--38931";
export var productCategory = "header-module--product-category--cc80b";
export var recipe = "header-module--recipe--ad3ba";
export var redButton = "header-module--red-button--0bb7a";
export var redTransparentButton = "header-module--red-transparent-button--06f6e";
export var regenBg = "header-module--regen-bg--1940e";
export var richTextItalic = "header-module--rich-text-italic--3de6b";
export var richTextP = "header-module--rich-text-p--17a84";
export var richtextImage = "header-module--richtext-image--9e263";
export var signUpButton = "header-module--sign-up-button--da147";
export var signupAdditionalDesc = "header-module--signup-additional-desc--6dec3";
export var signupDesc = "header-module--signup-desc--7dd60";
export var signupForm = "header-module--signupForm--48f6f";
export var surveysForms = "header-module--surveys-forms--e57ca";
export var transparentButton = "header-module--transparent-button--195d5";