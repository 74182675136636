import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const Layout = ({children}) => {
  return (
    <div>
        <Header />
        <main style={{ minHeight: 'calc( 100vh - 250px)' }} >
            <div>{children}</div>
        </main>
        <Footer />
    </div>
  )
}

export default Layout