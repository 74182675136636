import React from 'react'
import * as styles from './header.module.scss'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { useSiteQuery } from '../../../fragments/hooks/useSiteQuery'
import TopStrip from '../TopStrip/TopStrip'
import TopNav from '../TopNav/TopNav'
import MobileHeader from '../MobileHeader/MobileHeader'

const Header = () => {
    const {logo, headerMenuItems, navBarColor, topStripBackgroundColor, headerMenuMobile} = useSiteQuery()
    
  return (
    <>
    <MobileHeader logo = {logo}  headerMenuMobile = {headerMenuMobile} navBarColor = {navBarColor}/>
    <header className={styles.header}>
        <div className={`container`}>
            <TopStrip topStripBackgroundColor = {topStripBackgroundColor}/>
            <TopNav logo = {logo} headerMenuItems= {headerMenuItems} navBarColor = {navBarColor}/>
        </div>
    </header>
    </>
  )
}

export default Header