import React, { Children } from "react"
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { Options } from "@contentful/rich-text-react-renderer"
import { Bold, Italic, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Text } from "./Markdown"
import { GatsbyImage } from 'gatsby-plugin-image'
import {BsBoxArrowUpRight} from 'react-icons/bs'
import { Link } from "gatsby"
import {useLocalPath} from '../../hooks/useLocalPath'
// type BlogBodyProps = {
//     content: RenderRichTextData<ContentfulRichTextGatsbyReference>
// }

export const RichText = ({ content }) => {
    const options: Options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text) => {
                return (
                    <sup>{text}</sup>
                )
            },
            [MARKS.ITALIC]: (text) => {
                return <Italic>{text}</Italic>
            },
        },
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
            [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
            [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
            [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
            [BLOCKS.HEADING_5]: (node, children) => <Heading5>{children}</Heading5>,
            [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [INLINES.ENTRY_HYPERLINK]: ({
                data: {
                    target: {
                        slug
                    }
                }
            }, children) => {
                return (
                    <Link to={useLocalPath(slug)}><span>{children}</span></Link>
                )
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { gatsbyImageData, title } = node?.data?.target
                
                return (
                    <div className="richtext-image" >
                        <GatsbyImage image={gatsbyImageData} alt={`${title}`} className = 'blog-img'/>
                    </div>
                )
            },
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a
                        href={uri}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link opens in a new window"
                        title="Link opens in a new window"
                    >{children} <BsBoxArrowUpRight fontSize='0.8rem'/></a>
                )
            }
        },
    }
    return (
        <div className="blog-body">{renderRichText(content, options)}</div>
    )
}