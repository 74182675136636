import React from 'react'
import * as styles from './popup.module.scss'
//import Close from '../../../static/close-popup-icon.svg'
import { AiOutlineClose } from 'react-icons/ai'

const CookiePopup = ({
    setOpen
}) => {
    return (
        <>
            <div className={styles.overlay} onClick={e =>setOpen(false)}>&nbsp;</div>
            <div className={styles.container}>
                <AiOutlineClose className={styles.close} onClick={e =>setOpen(false)} />
                <div className={`uc-embed ${styles.content}`} uc-styling="true" uc-embedding-type="category" uc-data="all"></div>
            </div>
        </>
    )
}

export default CookiePopup