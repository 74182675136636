import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { useLocale } from "../hooks/useLocale"
import { cookieLang, userCentric, bazarvoice } from "../../utils/mapping"

const Scripts = () => {
  const [active, setActive] = useState(false)

  const location = useLocation()

  const events = [
    "keypress",
    "mousemove",
    "touchstart",
    "touchmove",
    "click",
    "scroll",
  ]
  const locale = useLocale()
  const language = locale ? cookieLang[locale] : cookieLang["cafe-collective"]
  const settingId = locale
    ? userCentric[locale]
    : userCentric["cafe-collective"]
  // console.log("settingId", settingId)

  const bv = bazarvoice[locale]
  const bvMarket =
    locale && locale.split("-")[1] === "US" ? "tazo-tea" : "tazo-canada"

  // <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-version="preview" data-settings-id="QasplMwig" async></script>

  const cookieScriptInit = () => {
    const script = document.createElement("script")
    script.setAttribute("id", "usercentrics-cmp")
    script.setAttribute(
      "src",
      "https://app.usercentrics.eu/browser-ui/latest/loader.js"
    )
    script.setAttribute("data-settings-id", settingId)
    script.setAttribute("data-language", language)
    script.setAttribute("async", "true")
    document.head.appendChild(script)
  }

  const gtag = () => {
    const script = document.createElement("script")
    script.setAttribute("id", "script-gtag")
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("consent", "default", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 1000
    });`
    script.setAttribute("async", "true")
    document.head.appendChild(script)
}

  const gtm = () => {
    const script = document.createElement("script")
    script.setAttribute("id", "script-gtm")
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NZPGCQQ');`
    document.head.appendChild(script)

    const noScript = document.createElement("noscript")
    noScript.setAttribute("id", "noscript-gtm")
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NZPGCQQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    document.body.appendChild(noScript)
  }

  const bazaarVoice = () => {
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      `https://apps.bazaarvoice.com/deployments/${bvMarket}/main_site/production/${bv}/bv.js`
    )
    script.setAttribute("async", "true")
    document.head.appendChild(script)
  }

  const priceSpider = () => {
    const script = document.createElement("script")
    script.setAttribute("src", "//cdn.pricespider.com/1/lib/ps-widget.js")
    script.setAttribute("async", "true")
    document.head.appendChild(script)
  }

  const bvCarousel = () =>{
    const script = document.createElement('script')
    script.setAttribute('id', 'bv-carousel')
    script.innerHTML = `var CRL8_SITENAME = 'tazo-avpxct';!function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.async=!0,o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();`
    document.head.appendChild(script)
  }

  const handleEvent = () => {
    setActive(true)
    cookieScriptInit()
    if (!document.getElementById("script-gtag")) gtag()
    if (!document.getElementById("script-gtm")) gtm()
    priceSpider()
    bazaarVoice()
    bvCarousel()
    events.forEach(event => window.removeEventListener(event, handleEvent))
  }

  useEffect(() => {
    events.forEach(event => window.addEventListener(event, handleEvent))
  }, [location])

  setTimeout(() => {
    !active && handleEvent()
  }, 6000)

  return <></>
}

export default Scripts
