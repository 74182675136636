exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-contact-tsx": () => import("./../../../src/templates/PageContact.tsx" /* webpackChunkName: "component---src-templates-page-contact-tsx" */),
  "component---src-templates-page-email-us-tsx": () => import("./../../../src/templates/PageEmailUs.tsx" /* webpackChunkName: "component---src-templates-page-email-us-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-page-search-tsx": () => import("./../../../src/templates/PageSearch.tsx" /* webpackChunkName: "component---src-templates-page-search-tsx" */),
  "component---src-templates-page-sitemap-tsx": () => import("./../../../src/templates/PageSitemap.tsx" /* webpackChunkName: "component---src-templates-page-sitemap-tsx" */),
  "component---src-templates-page-thank-you-tsx": () => import("./../../../src/templates/PageThankYou.tsx" /* webpackChunkName: "component---src-templates-page-thank-you-tsx" */),
  "component---src-templates-product-blog-tsx": () => import("./../../../src/templates/ProductBlog.tsx" /* webpackChunkName: "component---src-templates-product-blog-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-category-tsx": () => import("./../../../src/templates/ProductsCategory.tsx" /* webpackChunkName: "component---src-templates-products-category-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/Recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-recipes-category-tsx": () => import("./../../../src/templates/RecipesCategory.tsx" /* webpackChunkName: "component---src-templates-recipes-category-tsx" */),
  "component---src-templates-sign-up-tsx": () => import("./../../../src/templates/SignUp.tsx" /* webpackChunkName: "component---src-templates-sign-up-tsx" */),
  "component---src-templates-where-to-buy-tsx": () => import("./../../../src/templates/WhereToBuy.tsx" /* webpackChunkName: "component---src-templates-where-to-buy-tsx" */)
}

