import React from "react";
import { useLocale } from "./useLocale";
import { mapLocale } from "../../utils/mapping";

export const useLocalPath = (slug) => {
    const locale = useLocale()
    let countrySlug = mapLocale[locale] || 'us/en'
    if (slug === '') return `/${countrySlug}/`
    if (!slug) {
        return null
    } else {
        if (slug === '/') return `/${countrySlug}/`
        else if (slug[0] === '/') {
            if (slug[slug.length - 1] === '/') return `${countrySlug}/${slug}`
            else return `${countrySlug}/${slug}/`
        }
        else if (slug[slug.length - 1] === '/') return `/${countrySlug}/${slug}`
        else return `/${countrySlug}/${slug}/`
    }
}