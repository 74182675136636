import React from "react"
import Layout from "./src/components/Layout/Layout"
import { LocaleContext, FiltersProvider } from "./src/utils/context"
import "./src/styles/main.scss"
import Scripts from "./src/components/Layout/Script"
export const wrapPageElement = ({ 
    element, 
    props: {
        pageContext: { 
            site,
            node_locale,
            multiLang
        }
    } }) => {
    return (
        <FiltersProvider>
            <LocaleContext.Provider value={{ locale: node_locale, multiLang }}>
                <>
                    <Scripts />
                    <Layout>{element}</Layout>
                </>
            </LocaleContext.Provider>
        </FiltersProvider>
    )
}