import React from "react"

type NodeProps = {
    children: React.ReactNode
}

export const Bold = ({ children }: NodeProps) => <strong>{children}</strong>

export const Italic = ({children}: NodeProps) => <em className="rich-text-italic"> {children}</em>

export const Text = ({ children }: NodeProps) => (
    <p>{children}</p>
)

export const Heading1 = ({ children }: NodeProps) => (
    <h1>{children}</h1>
)

export const Heading2 = ({ children }: NodeProps) => (
    <h2>{children}</h2>
)
export const Heading3 = ({ children }: NodeProps) => (
    <h3>{children}</h3>
)
export const Heading4 = ({ children }: NodeProps) => (
    <h4>{children}</h4>
)
export const Heading5 = ({ children }: NodeProps) => (
    <h5>{children}</h5>
)
export const Heading6 = ({ children }: NodeProps) => (
    <h6>{children}</h6>
)
